import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";

// Bootstrap components
import "bootstrap/dist/css/bootstrap.min.css";

declare global {
    interface Window {
        msalAuthorityUrl: string;
        msalClientId: string;
        baseHostPageUrl: string;
    }
}

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);
