import React, { useState, useEffect } from "react";

import { PageLayout } from "../auth/components/PageLayout";
import { loginRequest } from "../auth/authConfig";
import { SignInButton } from "../auth/components/SignInButton";
import securityInitiatives from "../SecurityInitiatives.json";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import "./App.css";

import Modal from "react-modal";
import {
  PrimaryButton,
  Dropdown,
  IDropdownOption,
  Stack,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  Text,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";

interface Initiative {
  Id: number;
  Team: string;
  Date: string;
  Title: string;
}

Modal.setAppElement("#root");

const App: React.FC = () => {
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length === 0 && inProgress === "none") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
      });
    }
  }, [instance, inProgress, accounts]);
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [selectedInitiative, setSelectedInitiative] =
    useState<Initiative | null>(null);
  const [contentOption, setContentOption] = useState<string>("");
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [glow, setGlow] = useState<boolean>(false);
  const [displayedInitiatives, setDisplayedInitiatives] = useState<
    Initiative[]
  >([]);
  const [displayCount, setDisplayCount] = useState<number>(10);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [hostPage, setHostPage] = useState<string>("");

  const contentOptions = ["ADO", "Email", "ICM", "S360"];

  useEffect(() => {
    setHostPage(window.baseHostPageUrl);
    setInitiatives(securityInitiatives);
    setDisplayedInitiatives(securityInitiatives.slice(0, displayCount));
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSelectInitiative = (initiative: Initiative) => {
    setSelectedInitiative(initiative);
    closeModal();
  };

  const handleContentOptionChange = (option: string) => {
    setContentOption(option);
  };

  const handleGenerateLinks = () => {
    if (!selectedInitiative || !contentOption) {
      setErrorMessages(
        "Please select an initiative and a content option before generating links."
      );
      return;
    }
    setErrorMessages("");
    setGlow(true);
    setTimeout(() => setGlow(false), 500);
    if (selectedInitiative && contentOption) {
      const positiveLink = `${hostPage}/${selectedInitiative.Id}/positive`;
      const negativeLink = `${hostPage}/${selectedInitiative.Id}/negative`;

      setFeedbackMessage(
        `Please provide feedback on this ${contentOption} item 👍<a href="${positiveLink}">Positive</a> 👎<a href="${negativeLink}">Negative</a>`
      );
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(feedbackMessage);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const dropdownOptions: IDropdownOption[] = contentOptions.map(
    (option, index) => ({
      key: index,
      text: option,
    })
  );

  const columns: IColumn[] = [
    {
      key: "id",
      name: "ID",
      fieldName: "Id",
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "team",
      name: "Team",
      fieldName: "Team",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "date",
      name: "Date",
      fieldName: "Date",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "title",
      name: "Title",
      fieldName: "Title",
      minWidth: 300,
      maxWidth: 400,
      isResizable: true,
    },
  ];

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const atBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;
    if (atBottom) {
      setDisplayCount((prevCount) => prevCount + 10);
    }
  };

  useEffect(() => {
    setDisplayedInitiatives(initiatives.slice(0, displayCount));
  }, [displayCount, initiatives]);

  return (
    <PageLayout>
      <div className="App">
        <AuthenticatedTemplate>
          <Text variant="xxLarge" style={{ marginBottom: "20px" }}>
            Feedback Link Generator
          </Text>
          <Stack tokens={{ childrenGap: 20, padding: "20px 0" }}>
            <PrimaryButton
              className="big-button"
              onClick={openModal}
              text={"Select Initiative"}
            />
            {selectedInitiative && (
              <Text variant="mediumPlus">
                Selected Initiative: {selectedInitiative.Title}
              </Text>
            )}
            {selectedInitiative && (
              <DetailsList
                items={[selectedInitiative]}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
              />
            )}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Initiative Selector"
              className="Modal"
            >
              <div
                onScroll={handleScroll}
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                <DetailsList
                  items={displayedInitiatives}
                  columns={columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.single}
                  onActiveItemChanged={handleSelectInitiative}
                />
              </div>
              <Text style={{ textAlign: "center", position: "absolute" }}>
                {" "}
                Don't see your initiative?{" "}
                <a href="mailto:cseenghubesfun@microsoft.com">Contact us</a>
              </Text>
            </Modal>
            <Dropdown
              className="big-button"
              placeholder="Select Content Option"
              options={dropdownOptions}
              onChange={(_, item) =>
                item && handleContentOptionChange(item.text)
              }
            />
            <button
              className={glow ? "low-glow-button big-button" : "big-button"}
              onClick={handleGenerateLinks}
            >
              Generate Links
            </button>
            {errorMessages && (
              <MessageBar messageBarType={MessageBarType.error}>
                {errorMessages}
              </MessageBar>
            )}
            {feedbackMessage && (
              <div className="feedback-box">
                <div className="copy-container">
                  <button
                    onClick={handleCopyToClipboard}
                    className="copy-button"
                  >
                    {copied ? "✓ Copied!" : "Copy to clipboard"}
                  </button>
                </div>
                <div className="message-container">
                  <p dangerouslySetInnerHTML={{ __html: feedbackMessage }}></p>
                </div>
              </div>
            )}
          </Stack>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignInButton />
        </UnauthenticatedTemplate>
      </div>
    </PageLayout>
  );
};

export default App;
