import React from "react";
import { useMsal } from "@azure/msal-react";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeIcons } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
initializeIcons();

const MyIcon = () => <Icon iconName="Contact" style={{ marginLeft: "10px" }} />;

export const SignOutButton = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Dropdown alignRight>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        style={{ color: "white" }}
      >
        <span>
          {accounts[0]?.name}
          {MyIcon()}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
